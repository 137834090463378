
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import users from '@/services/users';
import BaseInput from '@/components/ui/BaseInput.vue';
import SmallSpinner from '@/components/SmallSpinner.vue';
import reports from '@/services/reports';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import timeService from '@/services/timeService';

export default defineComponent({
  name: 'ReportLogs',
  components: {
    SearchDropdown,
    BaseDatePicker,
    BaseIcon,
    BaseInput,
    SmallSpinner
  },
  data: () => ({
    isLoading: false,
    textAction: '',
    users: [] as any[],
    chosenUsernames: [] as string[],
    chosenUserActions: [] as string[],
    chosenDates: {
      endDate: null,
      startDate: null
    } as { endDate: null | number, startDate: null | number},
  }),
  methods: {
    addUserAction() {
      if (this.textAction?.length) {
        this.chosenUserActions.push(this.textAction)
        this.textAction = ''
      }
    },
    setDateRange(range: any) {
      this.chosenDates.endDate = new Date(range.end).getTime()
      this.chosenDates.startDate = new Date(range.start).getTime()
    },
    setDefault() {
      this.textAction = ''
      this.chosenUsernames = []
      this.chosenUserActions = []
    },
    async fetchReportLogs() {
      const payload = {
        actions: [...(this.chosenUserActions || [])],
        users: [...(this.chosenUsernames || [])],
        ...(this.chosenDates.startDate && { startDateTime: this.chosenDates.startDate }),
        ...(this.chosenDates.endDate && { endDateTime: this.chosenDates.endDate }),
      }

      try {
        this.isLoading = true
        let date = timeService.createDateForViewFromUTCTime();
        const table = await reports.generateReportLogs(payload)
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `report_logs_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.setDefault()
        this.isLoading = false
      }
    },
    setUsername(username: string) {
      if (username) {
        this.chosenUsernames.push(username)
      }
    },
    removeUsername(usernameIndex: number) {
      this.chosenUsernames.splice(usernameIndex, 1)
    },
    removeUserAction(userActionIndex: number) {
      this.chosenUserActions.splice(userActionIndex, 1)
    }
  },
  async mounted() {
    this.users = await users.getAllUsers()
  }
})
