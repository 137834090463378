
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import debounce from "@/components/debounce";
import routerService from '@/services/routerService';
import router from '@/router';

export default defineComponent({
  name: 'BaseTabs',
  emits: ['setActiveTab'],
  props: {
    tabName: {
      type: String,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    defaultTab: {
      type: Object,
    }
  },
  methods: {
    choseTab(tab: any) {
      routerService.setQuery(this.tabName, tab.query, this.$route.query, this.$router)
      this.$emit('setActiveTab', tab)
    }
  },
  mounted() {
    const index = this.tabs.findIndex((tab: any) => tab.query === this.$route.query[this.tabName])
    let chosenTab = null
    const [firstTab] = this.tabs

    if (index !== -1) {
      chosenTab = this.tabs[index]
    } else {
      chosenTab = firstTab
    }

    this.choseTab(chosenTab)
  }
})
