import {httpClient} from "@/httpClient";

function getArticlesByVehicleToFile(payload: object) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/image_is_exists`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function getArticleCard(payload: object) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/article_card`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function leftRight(payload: object) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/article_pairs`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function analogs(payload: object) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/article_analogs`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function counts(payload: object) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/article_counts`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function generateArchivePhotos(payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/archive`, payload, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

function generateReportLogs(payload: object) {
  return httpClient.post(`/admin/${localStorage.getItem('cid')}/audit`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}

function articleReplacedReport() {
  return httpClient.get(`/reports/${localStorage.getItem('cid')}/article/article_replaced`, {
    responseType: 'blob'
  }).then(response => response.data)
}

function articleVgh(payload: any) {
  return httpClient.post(`/reports/${localStorage.getItem('cid')}/article/article_vgh`, payload, {
    responseType: 'blob'
  }).then(response => {
    console.log('Response', response)
    return response.data
  })
}

export default {
  articleVgh,
  articleReplacedReport,
  generateReportLogs,
  generateArchivePhotos,
  getArticlesByVehicleToFile,
  getArticleCard,
  leftRight,
  analogs,
  counts,
}
