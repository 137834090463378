
function changeQuery(queryName: any, queryValue: any, quires: any, path: any, otherPath?: any) {
  const query = Object.assign({}, quires);

  query[queryName] = queryValue;

  return {
    path: otherPath ? `${path}/${otherPath}` : path,
    query,
  }
}

async function clearAllQuery($router: any) {
  let query = {};
  try {
    await $router.replace({ query })
  } catch(error) {}
}

async function setQuery(queryName: any, queryValue: any, queries: any, $router: any) {
  const query = Object.assign({}, queries);
  query[queryName] = queryValue

  try {
    await $router.replace({ query })
  } catch (error) {}
}

async function changeGroupQuery(quiresData: any, quires: any, path: any) {
  const query = Object.assign({}, quires);

  Object.keys(quiresData).map((queryName: string) => {
    query[queryName] = quiresData[queryName]
  })

  return {
    path,
    query
  }
}

async function clearQuery(queryName: any, queries: any, $router: any) {
  const query = Object.assign({}, queries)
  delete query[queryName]
  try {
    await $router.replace({ query }).catch(() => {})
  } catch (error) {}
}

async function clearQueries(queryNames: Array<string>, queries: any, $router: any) {
  const query = Object.assign({}, queries)

  queryNames.map(async (queryName: string) => {
    delete query[queryName]
    try {
      await $router.replace({ query }).catch(() => {})
    } catch (error) {}
  })
}

export default {
  changeQuery,
  setQuery,
  changeGroupQuery,
  clearAllQuery,
  clearQuery,
  clearQueries
}
