<template>
  <div class="toggle-button">
    <div
      class="toggle-button__trigger_currentry"
      :class="`toggle-button__trigger_${newState ? 'right' : 'left'}`"
    ></div>
    <div
      class="toggle-button__trigger toggle-button__trigger_off"
      @click="newState ? toggleState(false) : ''"
      :class="{'toggle-button__trigger_active': !newState}"
    >Выкл.</div>
    <div
      class="toggle-button__trigger toggle-button__trigger_on"
      :class="{'toggle-button__trigger_active': newState}"
      @click="!newState ? toggleState(true) : ''"
    >Вкл.</div>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue'

export default defineComponent({
  name: 'ToggleButton',
  props: {
    state: Boolean,
    ids: Object
  },
  setup(props, {emit}) {
    const newState = ref(props.state)

    const toggleState = (state) => {
      newState.value = !newState.value
      emit('changeState', newState.value, props.ids)
    }

    return {
      toggleState,
      newState
    }
  }
})
</script>

<style lang="scss" scoped>
.toggle-button {
  display: flex;
  border-radius: 4px;
  background: white;
  outline: 1px solid $color-grey-5;
  position: relative;
  width: 10rem;

  &__trigger {
    height: 2rem;
    width: 5rem;
    text-align: center;
    cursor: pointer;

    &_on:before {

      content: 'Вкл.';
      position: absolute;
    }

    &_off:before {
      content: 'Выкл.';
      position: absolute;
    }

    &_left {
      left: 0;
      background: $color-grey-3;
    }

    &_right {
      right: 0;
      background: green;
    }

    &_active {
      color: white;
    }

    &_currentry {
      position: absolute;
      width: 5rem;
      height: 2rem;
    }
  }
}
</style>
