
import { defineComponent } from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import reports from '@/services/reports';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import ToggleButton from '@/components/admin-panel/ToggleButton.vue';
import { Category, Status, Type } from '@/components/types/enums';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import timeService from '@/services/timeService';
import Spinner from '@/components/Spinner.vue';
import ReportPhotos from '@/components/reports/ReportPhotos.vue';
import ReportLogs from '@/components/reports/ReportLogs.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import barcodes from '@/services/barcodes';
import globalMixin from '@/mixins/globalMixin';
import SmallSpinner from '@/components/SmallSpinner.vue';

enum Reports {
  PHOTO_AND_DRAW = 'Фото/чертежи',
  PHOTO_AND_DRAW_ARCHIVE = 'Фото/чертежи архивом',
  LOGS = 'Логи',
  CARDS = 'Карточки',
  PAIRS = 'Парность',
  NUMBERS_OF = 'Кол-во (артикулов, применяемости, кроссов, параметров)',
  ANALOGS = 'Аналоги',
  BARCODES = 'Штрихкоды',
  ARTICLE_REPLACED = 'Замены',
  ARTICLE_VGH = 'ВГХ'
}

export default defineComponent({
  name: 'ReportsMainView',
  mixins: [globalMixin],
  components: {
    BaseSelect,
    ReportLogs,
    ReportPhotos,
    BaseIcon,
    SearchDropdown,
    ToggleButton,
    BaseDatePicker,
    Spinner,
    SmallSpinner,
  },
  data: () => ({
    reportOptions: Object.keys(Reports).map((key: string) => Reports[key as keyof typeof Reports]),
    chosenReport: Reports.PHOTO_AND_DRAW,
    selectedData: {
      brandFrom: 2105, // default FENOX
      showStatus: false,
      articleIds: [],
      startDate: null,
      endDate: null
    } as any,
    categories: Category as any,
    statuses: Status as any,
    types: Type as any,
    brandTo: null as any,
    productGroup: null as any,
    productSubGroup: null as any,
    productSubSubGroup: null as any,
    selectedArticles: [] as any,
    loading: false as boolean,
  }),
  computed: {
    chosenReportIsArticleReplaced(): boolean {
      return this.chosenReport === Reports.ARTICLE_REPLACED
    },
    chosenReportIsLogs(): boolean {
      return this.chosenReport === Reports.LOGS
    },
    chosenReportIsPhotoAndDrawsArchive(): boolean {
      return this.chosenReport === Reports.PHOTO_AND_DRAW_ARCHIVE
    },
    isShowDatepicker(): boolean {
      return this.chosenReport === Reports.CARDS
    },
    isItemSelected() {
      return (param: any, item: any): any => {
        return this.selectedData &&
          this.selectedData[param] && this.selectedData[param].includes(item)
      }
    },
    lastArticle(): any {
      const article = this.selectedArticles[this.selectedArticles.length - 1]
      return article && article.article
        ? article.article
        : ''
    }
  },
  methods: {
    setDateRange(range: any) {
      this.selectedData.endDate = new Date(range.end).getTime()
      this.selectedData.startDate = new Date(range.start).getTime()
    },
    setProductGroup(levelGroup: any, productGroup: any) {
      if (typeof productGroup !== 'string' && productGroup) {
        //@ts-ignore
        this[levelGroup] = productGroup
      } else {
        switch (levelGroup) {
          case 'productGroup':
            this.productSubSubGroup = null
            this.productSubGroup = null
            break;
          case 'productSubGroup':
            this.productSubSubGroup = null
            break;
        }
        //@ts-ignore
        this[levelGroup] = null
      }
    },
    unsetArticle(articleId: number) {
      this.selectedArticles = this.selectedArticles.filter((article: any) => {
        return article.id !== articleId
      })
    },
    setArticle(article: any) {
      if (article) {
        this.selectedArticles.push(article)
      }
    },
    changeState(status: boolean) {
      this.selectedData.showStatus = status
    },
    setBrand(brand: any) {
      if (brand) {
        this.brandTo = brand
        this.selectedData.brandTo = brand.brandId
      } else {
        this.brandTo = null
        delete this.selectedData.brandTo
      }
    },
    setParam(type: string, value: any) {
      const isItemExist = this.selectedData &&
        this.selectedData[type] &&
        this.selectedData[type].findIndex((item: string) => item === value) >= 0

      if (!isItemExist) {
        this.selectedData[type] = [
          ...(
            this.selectedData && this.selectedData[type]
              ? this.selectedData[type]
              : []
          ),
          value
        ]
      } else {
        this.selectedData[type] = this.selectedData[type].filter((item: any) => {
          return item !== value
        })
      }
    },
    async getFile() {
      const {
        showStatus,
        categories,
        saleTypes,
        statuses,
        startDate,
        endDate
        // brandFrom,
        // brandTo
      } = this.selectedData

      const payload = {
        articleIds: [...this.selectedArticles.map((article: any) => article.id)],
        showStatus,
        ...(this.productGroup && { productGroups: [this.productGroup.id] }),
        ...(this.productSubGroup && { productSubGroups: [this.productSubGroup.id] }),
        ...(this.productSubSubGroup && { productSubSubGroups: [this.productSubSubGroup.id] }),
        ...(statuses && { statuses }),
        ...(saleTypes && { saleTypes }),
        ...(categories && { categories }),
        ...(startDate && { startDate }),
        ...(endDate && { endDate }),
      }
      this.loading = true

      try {
        let table
        if (this.chosenReport === Reports.PHOTO_AND_DRAW) {
          table = await reports.getArticlesByVehicleToFile(payload)
        } else if (this.chosenReport === Reports.CARDS) {
          table = await reports.getArticleCard(payload)
        } else if (this.chosenReport === Reports.PAIRS) {
          table = await reports.leftRight(payload)
        } else if (this.chosenReport === Reports.NUMBERS_OF) {
          table = await reports.counts(payload)
        } else if (this.chosenReport === Reports.ANALOGS) {
          table = await reports.analogs(payload)
        } else if (this.chosenReport === Reports.BARCODES) {
          table = await barcodes.reportBarcodes(payload)
        } else if (this.chosenReport === Reports.ARTICLE_REPLACED) {
          table = await reports.articleReplacedReport()
        } else if (this.chosenReport === Reports.ARTICLE_VGH) {
          table = await reports.articleVgh(payload)
        }

        let date = timeService.createDateForViewFromUTCTime();
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Отчет_${this.chosenReport}_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.loading = false
      }
    }
  },
})
