
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import BaseInput from './BaseInput.vue';
import { Category, Status, Type } from '@/components/types/enums';
import articles from '@/services/articles';
import ozon from '@/services/ozon';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import { CertificateRequestsStatus } from '@/models/CertificateModel';

export default defineComponent({
  name: 'BaseSelect',
  emits: ['update', 'clearSelect'],
  components: {
    BaseInput,
    BaseIcon
  },
  props: {
    saveChosenItem: Boolean,
    abilityRemove: {
      type: Boolean,
      default: () => false
    },
    item: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    options: {
      type: Array,
      default: () => []
    },
    itemName: {
      type: Function,
      required: false
    }
  },
  setup(props, {emit}) {
    const selectOptions = ref({
      status: [],
      category: [],
      codetype: [],
      ozon_category: [],
      ozon_category_parameter: [],
      confirmed_articles_parameter: [],
      status_request_certificate: [],
      another_options: [],
    } as any);
    const editedItem = ref({} as any);
    const categories = ref([] as any);
    const getName = computed(() => {
      return (item: any) => {
        if (props.type === 'OZON_CATEGORY') {
          return item.title
        } else if (props.type === 'OZON_CATEGORY_PARAMETER') {
          return item.value
        } else if (props.type === 'CONFIRMED_ARTICLE_PARAMETER') {
          return item.name + ' - ' + item.value
        } else if (props.type === 'STATUS_CERTIFICATE_REQUEST') {
          return item.value
        } else {
          if (props.itemName) {
            return props.itemName(item)
          }

          return typeof item === 'object'
            ? Object.values(item)[0]
            : item
        }}
    });
    const currentOptions = computed(() => {
      return selectOptions.value[props?.type.toLowerCase() || 'another_options']
    });


    watch(() => currentOptions.value, (options) => {
      editedItem.value = options.find((option: any) => {
        if (option[props.item] || option === props.item) {
          return option
        }
      })
    });

    watch(() => props.item, async (val) => {
      if (val) await setOptions()
    });

    function clearSelect() {
      localStorage.removeItem(props.type)

      editedItem.value = {}
      emit('clearSelect')
    }

    function clickOption() {
      props.type === 'OZON_CATEGORY' ||
      props.type === 'OZON_CATEGORY_PARAMETER' ||
      props.type === 'CONFIRMED_ARTICLE_PARAMETER' ||
      props.type === 'STATUS_CERTIFICATE_REQUEST' ||
      !props.type
        ? emit('update', editedItem.value)
        : emit('update', Object.keys(editedItem.value)[0])

      if (props.saveChosenItem) {
        localStorage.setItem(props.type, JSON.stringify(editedItem.value))
      }
    }

    async function getStatuses() {
      try {
        selectOptions.value.status = [];
        let status: { [x: string]: any } = Status;
        let result = await articles.getNextStatus(props.item);
        Object.keys(status).forEach(o => {
          if (result.includes(o)) selectOptions.value.status.push({[o]: status[o]})
        })
      } catch (error) {
        throw error
      }
    }

    async function setOptions() {
      let category: { [x: string]: string } = Category;
      let type: { [x: string]: string } = Type;
      let status: { [x: string]: string } = Status;
      selectOptions.value.category = Object.keys(category).map(key => {
        return {[key]: category[key]}
      });
      selectOptions.value.codetype = Object.keys(type).map(key => {
        return {[key]: type[key]}
      });
      if (props.type === 'STATUS' && props.item && props.item !== 'NEW_TEMP_STATUS') {
        // Карточка артикула
        await getStatuses();
        editedItem.value = {[props.item]: status[props.item]};
        selectOptions.value.status.push(editedItem.value);
      } else if (props.type === 'STATUS' && props.item === 'NEW_TEMP_STATUS') {
        // Лист освоения
        editedItem.value = {['TEMPORARY']: status['TEMPORARY']};
      } else if (props.type === 'STATUS') {
        // Все артикулы (поиск)
        selectOptions.value.status = Object.keys(status).map(key => {
          return {[key]: status[key]}
        })
      } else if (props.type === 'OZON_CATEGORY') {
        let categories = await ozon.getOzonCategory();
        selectOptions.value.ozon_category = categories
      } else if (props.type === 'OZON_CATEGORY_PARAMETER') {
        selectOptions.value.ozon_category_parameter = props.options
      } else if (props.type === 'CONFIRMED_ARTICLE_PARAMETER') {
        selectOptions.value.confirmed_article_parameter = props.options
      } else if (props.type === 'STATUS_CERTIFICATE_REQUEST') {
        selectOptions.value.status_certificate_request = [
          { value: null, type: null },
          ...Object.keys(CertificateRequestsStatus)
            .filter((key) => {
              return CertificateRequestsStatus[key  as keyof typeof CertificateRequestsStatus] !== CertificateRequestsStatus.SUCCESS
            }).map((key: any) => {
              return {
                value: CertificateRequestsStatus[key as keyof typeof CertificateRequestsStatus],
                type: key
              }
          })
        ]
      } else {
        selectOptions.value.another_options = [...props.options]
      }
    }

    function setSavedFilterItems() {
      const savedFilterItem = localStorage.getItem(props.type)

      if (savedFilterItem) {
        editedItem.value = JSON.parse(savedFilterItem)
        // pass key of editedItem (property name) for further search
        emit('update', Object.keys(editedItem.value)[0])
      }
    }

    onMounted(async () => {
      await setOptions()
      setSavedFilterItems()
    });

    return {editedItem, currentOptions, getName, clickOption, clearSelect}
  }
})
