import { httpClient } from "@/httpClient";

function getTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/barcodes/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}


function uploadBarcodes(file: FormData) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/barcodes/export`, file)
}

function reportBarcodes(payload: any) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/barcodes/report`, payload, {
    responseType: 'blob'
  }).then(response => response.data)
}


export default {
  getTemplate,
  uploadBarcodes,
  reportBarcodes
}
