import {httpClient} from '@/httpClient';

async function getOzonCategory() {
  const response = await httpClient.get(`/ozon/categories/${localStorage.getItem('cid')}`);
  return response.data;
}

async function getAttributesByCategory(categoryId: number) {
  const response = await httpClient.get(`/ozon/categories/${localStorage.getItem('cid')}/attributes/${categoryId}`);
  return response.data;
}

async function getAttributeValueByCategory(categoryId: number, attributeId: number) {
  const response = await httpClient.get(`/ozon/categories/${localStorage.getItem('cid')}/attributes/${categoryId}/${attributeId}?limit=399`);
  return response.data.result;
}

async function findByArticleId(articleId: number) {
  const response = await httpClient.get(`/article/${localStorage.getItem('cid')}/ozon/${articleId}`);
  return response.data;
}

async function addToOzon(articleId: number, article: any) {
  const response = await httpClient.post(`/ozon/products/${localStorage.getItem('cid')}/${articleId}`, article);
  return response.data;
}

async function getTaskInfo(taskId: number) {
  const response = await httpClient.get(`/ozon/products/${localStorage.getItem('cid')}/taskInfo/${taskId}`);
  return response.data;
}

async function getProductInfo(ozonId: number) {
  const response = await httpClient.get(`/ozon/products/${localStorage.getItem('cid')}/${ozonId}`);
  return response.data;
}

export default {
  getOzonCategory,
  getAttributesByCategory,
  getAttributeValueByCategory,
  findByArticleId,
  addToOzon,
  getTaskInfo,
  getProductInfo
}
