
import { defineComponent } from 'vue';
import { Calendar, DatePicker } from 'v-calendar';

export default defineComponent({
  name: 'BaseDatePicker',
  components: {
    Calendar,
    DatePicker
  },
  props: {
    anotherValue: {
      type: Object,
      default: {
        start: new Date(),
        end: new Date()
      }
    },
    title: {
      type: String,
      default: () => 'Дата:'
    },
    defaultValue: {
      type: Boolean,
      default: true
    },
    isRange: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    range: {
      start: new Date() as Date | null,
      end: new Date() as Date | null
    },
  }),
  computed: {
    getValueDatePicker() {
      return (value: any) => {

        if (!this.isRange) {
          return value
        }

        if (value.start === value.end) {
          return value.start
        }

        return value.start + '-' + value.end
      }
    }
  },
  watch: {
    range: {
      deep: true,
      handler() {
        this.$emit('setRange', this.range)
      }
    }
  },
  mounted() {
    if (!this.defaultValue) {
      this.range = { start: null, end: null }
    }
  }
})
