
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import reports from '@/services/reports';
import { ProductGroup } from '@/models/SystemModel';
import SmallSpinner from '@/components/SmallSpinner.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import timeService from '@/services/timeService';
import BaseTabs from '@/components/ui/BaseTabs.vue';

enum ReportType {
  IMAGE = 'IMAGE',
  DRAW = 'DRAW'
}


export default defineComponent({
  name: 'ReportPhotos',
  components: {
    BaseTabs,
    SearchDropdown,
    BaseDatePicker,
    BaseIcon,
    SmallSpinner,
  },
  data: () => ({
    tabName: 'export-file-by-type',
    tabs: [
      { name: 'Фото', query: ReportType.IMAGE },
      { name: 'Чертежи', query: ReportType.DRAW },
    ],
    chosenArticles: [] as any[],
    chosenDates: {
      endDate: null,
      startDate: null
    } as { endDate: null | number, startDate: null | number},
    chosenProductGroup: null as null | ProductGroup,
    isLoading: false,
  }),
  computed: {
    isImageType(): boolean {
      return this.reportType === ReportType.IMAGE
    },
    reportType(): ReportType {
      return this.$route.query?.[this.tabName] === ReportType.IMAGE
        ? ReportType.IMAGE
        : ReportType.DRAW
    },
    isDatesEmpty(): boolean {
      return !this.chosenDates.endDate && !this.chosenDates.startDate
    },
    isDisabled(): boolean {
      return Object.values({
        endDate: this.chosenDates.endDate,
        startDate: this.chosenDates.startDate,
        chosenProductGroup: this.chosenProductGroup,
        chosenArticles: this.chosenArticles.length ? [] : null,
      }).every((value: any) => {
        return !value
      })
    }
  },
  methods: {
    addArticle(article: any) {
      if (article) {
        this.chosenArticles.push(article)
      }
    },
    removeArticle(articleIndex: number) {
      this.chosenArticles.splice(articleIndex, 1)
    },
    setDateRange(range: any) {
      this.chosenDates.endDate = new Date(range.end).getTime()
      this.chosenDates.startDate = new Date(range.start).getTime()
    },
    setProductGroup(productGroup: any) {
      this.chosenProductGroup = productGroup
    },
    setDefault() {
      this.chosenProductGroup = null
      this.chosenArticles = []
    },
    async generateArchivePhotos(payload: any) {
      try {
        this.isLoading = true
        const archive = await reports.generateArchivePhotos(payload)

        const blob = new Blob([archive.data], { type: 'application/octet-stream' })
        let date = timeService.createDateForViewFromUTCTime();
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `report_photos_${date}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      } finally {
        this.setDefault()
        this.isLoading = false
      }
    },
    async fetchArchiveAllPhotos() {
      const payload = {
        type: this.reportType,
        articleIds: [],
        productGroupIds: [],
      }

      await this.generateArchivePhotos(payload)
    },
    async fetchArchivePhotos() {
      const payload = {
        type: this.reportType,
        articleIds: [...this.chosenArticles.map((article) => article.id)],
        productGroupIds: this.chosenProductGroup?.id
          ? [this.chosenProductGroup.id]
          : [],
        ...(this.chosenDates.startDate && { startDateTime: this.chosenDates.startDate }),
        ...(this.chosenDates.endDate && { endDateTime: this.chosenDates.endDate }),
      }

      await this.generateArchivePhotos(payload)
    }
  },
})
